import { render, staticRenderFns } from "./TheMegaMenu.vue?vue&type=template&id=491077e1&scoped=true&"
import script from "./TheMegaMenu.vue?vue&type=script&lang=js&"
export * from "./TheMegaMenu.vue?vue&type=script&lang=js&"
import style0 from "./TheMegaMenu.vue?vue&type=style&index=0&id=491077e1&prod&lang=css&"
import style1 from "./TheMegaMenu.vue?vue&type=style&index=1&id=491077e1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "491077e1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MegaMenuDesktop: require('/home/gitlab-runner/builds/KakjLXLD/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MegaMenu/MegaMenuDesktop.vue').default})
